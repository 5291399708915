import React from 'react';

import '../styles/loader.scss';

export const Loader = () => (
  <div className="ellipsis">
    <div />
    <div />
    <div />
    <div />
  </div>
);
