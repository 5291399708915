import React from 'react';
import Helmet from 'react-helmet';

import '../styles/layout.scss';
import { Header } from './header';
import { Loader } from './loader';

export class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <main>
        <Helmet>
          <html lang="en" />
          <meta
            name="Description"
            content="Front-end expert with nearly a decade of experience delivering intuitive, highly performant, and secure experiences."
          />
          <meta name="docsearch:version" content="2.0" />
          <meta name="og:site_name" content="Marina Berti" />
          <meta name="og:type" content="website" />
          <meta name="twitter:site" content="@codesqwerl" />
          <title>Marina Berti | Front-End Engineer</title>
        </Helmet>
        <Header />
        <section className="content">
          {this.state.loading && <Loader />}
          {this.props.children}
        </section>
      </main>
    );
  }
}
