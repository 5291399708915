import {
  faGithubAlt,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import '../styles/header.scss';

export const Header = () => (
  <header>
    <h1 className="title">
      <span>Marina Berti</span>
      <small>Front-End Engineer</small>
    </h1>
    <ul className="social">
      <li>
        <a title="Check out my Github projects" href="//github.com/codesqwerl/">
          <FontAwesomeIcon aria-hidden="true" icon={faGithubAlt} />
        </a>
      </li>
      <li>
        <a title="Tweet @codesqwerl" href="//twitter.com/codesqwerl">
          <FontAwesomeIcon aria-hidden="true" icon={faTwitter} />
        </a>
      </li>
      <li>
        <a title="Connect on LinkedIn" href="//linkedin.com/in/codesqwerl">
          <FontAwesomeIcon aria-hidden="true" icon={faLinkedinIn} />
        </a>
      </li>
      <li>
        <a title="Visit my portfolio" href="//codesqwerl.com">
          <FontAwesomeIcon aria-hidden="true" icon={faCode} />
        </a>
      </li>
      <li>
        <a
          data-email="marina@codesqwerl.com"
          title="Email me"
          href="mailto:marina@codesqwerl">
          <FontAwesomeIcon aria-hidden="true" icon={faEnvelope} />
        </a>
      </li>
    </ul>
  </header>
);
